import React, { Component } from 'react';
import './App.css';

import Login from './Login/Login';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img
            src="https://images.ctfassets.net/dzdkcgkmd6gm/5o5hV630GIdcMmrSl3
V2xk/db2a61841f1c0ffd7b4c3901d70f37aa/logo-cropped.png"
            className="App-logo"
            alt="logo"
          />
        </header>
        <Login />
      </div>
    );
  }
}

export default App;
