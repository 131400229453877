import React, { Component } from 'react';
import cx from 'classnames';
import cookies from 'js-cookie';

import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        email: '',
        password: '',
      },
      error: false,
    };

    console.log('Ready');
  }

  handleChange = (field) => (e) => {
    e.persist();
    this.setState((state) => ({
      values: { ...state.values, [field]: e.target.value },
      error: false,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    fetch('/proxy/auth', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state.values),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error();
        }
        return response.json();
      })
      .then((response) => {
        cookies.set('staging-token', response.token, {
          domain: '.skilltitan.com',
          sameSite: 'none',
          secure: true,
          expires: 30,
          Partitioned: true,
        });
        window.location.reload(true);
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  render() {
    const { values, error } = this.state;
    return (
      <form className="Login clearfix" onSubmit={this.handleSubmit}>
        <div className="Login-inputs">
          <div>
            <label className="Login-label" htmlFor="login-email">
              Email
            </label>
            <input
              className="Login-input"
              type="text"
              id="login-email"
              onChange={this.handleChange('email')}
              value={values.email}
            />
          </div>
          <div>
            <label className="Login-label" htmlFor="login-password">
              Password
            </label>
            <input
              className="Login-input"
              type="password"
              id="login-password"
              onChange={this.handleChange('password')}
              value={values.password}
            />
          </div>
        </div>
        <button className="Login-btn" type="submit">
          Login
        </button>
        <div className={cx('Login-error', { 'is-error': error })}>
          The email or password provided was incorrect
        </div>
      </form>
    );
  }
}

export default Login;
